import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Button,
} from "@mui/material";
import { FileIcon, defaultStyles } from "react-file-icon";
import { Download } from "@mui/icons-material";
import { ConversationAPI } from "services/ConversationAPI";

const GOOGLE_DOCS_VIEWER = "https://docs.google.com/gview";
const PREVIEW_SIZE_LIMIT = 200 * 1024; // 200 KB in bytes

const formatFileSize = (bytes) => {
  if (!bytes) return null;
  const kb = bytes / 1024;
  return kb < 1024 ? `${Math.round(kb)} KB` : `${(kb / 1024).toFixed(2)} MB`;
};

const supportedExtensions = [
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "txt",
];

const DocMessage = ({ url, message }) => {
  const [fileName, setFileName] = useState("");
  const [extension, setExtension] = useState("");
  const [fileSize, setFileSize] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSupported, setIsSupported] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  /* 
Function to google drive viewer url for the document
*/
  const getUniqueViewerUrl = (baseUrl) =>
    `${GOOGLE_DOCS_VIEWER}?embedded=true&url=${encodeURIComponent(
      baseUrl
    )}&ts=${Date.now()}`;

  useEffect(() => {
    /* 
    Split the url to get the name of the document
    
    */
    const parts = url.split("/");
    const name = parts[parts.length - 1] || "Document";
    setFileName(name);
    const ext = name.split(".").pop()?.toLowerCase() || "";
    setExtension(ext);

    setIsSupported(supportedExtensions.includes(ext));

    /*
    Fetch the file size of the document
    */
    ConversationAPI.fetchMediaUrl({ url })
      .then((response) => {
        const size = response.headers.get("content-length");
        if (size) setFileSize(parseInt(size, 10));
      })
      .catch((err) => console.error("Error fetching file size:", err))
      .finally(() => setLoading(false));
  }, [url]);

  const docType = extension.toUpperCase();
  const readableSize = formatFileSize(fileSize);

  return (
    <Box
      sx={{
        backgroundColor: "#f8f8f8",
        border: "1px solid #ddd",
        borderRadius: 2,
        p: 1.5,
        width: "100%",
        maxWidth: "400px",
        overflow: "hidden",
      }}
    >
      {/* Showign preview directly for docs under 200Kb and 
    giving user option to preview docs which are above 200Kb 
    by choice to save resource */}
      {isSupported && (
        <Box
          sx={{
            position: "relative",
            height: 250,
            backgroundColor: "#fafafa",
            border: "1px solid #ccc",
            borderRadius: 1,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 1,
          }}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : fileSize > PREVIEW_SIZE_LIMIT && !showPreview ? (
            <Button
              variant="text"
              onClick={() => setShowPreview(true)}
              sx={{
                px: 3,
                py: 1.5,
                // border: "1px solid #1976d2",
                color: "#000",
                textTransform: "none",
                // borderRadius: "25px",
                fontSize: "14px",
                fontWeight: 600,
                // transition: "all 0.3s ease",
                // "&:hover": {
                //   backgroundColor: "#1976d2",
                //   color: "#fff",
                //   borderColor: "#1565c0",
                //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                // },
              }}
            >
              Preview
            </Button>
          ) : (
            <iframe
              src={getUniqueViewerUrl(url)}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
              title="Document Preview"
            ></iframe>
          )}
        </Box>
      )}

      <Box display="flex" alignItems="center" gap={1}>
        <Box flexShrink={0} sx={{ width: 30, height: 30 }}>
          <FileIcon
            extension={extension}
            {...(defaultStyles[extension] || defaultStyles.doc)}
            style={{ width: "100%", height: "100%" }}
            radius={10}
          />
        </Box>

        <Box flex="1 1 auto" minWidth={0}>
          <Typography
            variant="body2"
            fontWeight="bold"
            noWrap
            sx={{ color: "#333" }}
          >
            {message?.message || fileName}
          </Typography>
          <Typography variant="caption" sx={{ color: "#666" }}>
            {readableSize && `${readableSize} ┃ `} {docType}
          </Typography>
        </Box>

        <IconButton size="small" sx={{ color: "#000" }}>
          <Download sx={{ fontSize: 20 }} onClick={() => window.open(url)} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DocMessage;

import RenderUnknownMediaFormat from "components/util/ChatCompose/RenderUnknownMediaFormat";
import AppConstants from "constants/AppConstants";
import { MEDIA_TYPE } from "constants/ChatConstants";
import MDBox from "lib/components/MDBox";
import { MessageImage } from "pages/Chat/ChatStyles";
import { useEffect, useRef, useState } from "react";
import { getMediaType } from "util/MediaUtil";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import { CircularProgress, Divider } from "@mui/material";
import VCFReader from "./VCFReader";
import DocMessage from "./DocMessage";
import TextReader from "./TextReader";

import { TELE_MESSAGE_STATUS } from "constants/ChatConstants";

const MediaMessage = ({ message }) => {
  const fullscreenRef = useRef(null);
  const [media, setMedia] = useState();
  const [viewMedia, setViewMedia] = useState(false);

  const renderMedia = (mData) => {
    if (mData.length > 1) {
      const srcArrImages = mData.filter(
        (itm) => itm.type == MEDIA_TYPE.IMAGE || itm.type == MEDIA_TYPE.VIDEO
      );
      const srcArrOther = mData.filter(
        (itm) => itm.type != MEDIA_TYPE.IMAGE && itm.type != MEDIA_TYPE.VIDEO
      );
      return (
        <MDBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {srcArrImages.length > 1 && (
            <div
              style={{
                width: "100%",
                height: "300px",
                maxWidth: "100%",
                aspectRatio: "3 / 2",
              }}
            >
              <Lightbox
                plugins={[Inline, Fullscreen]}
                open={viewMedia}
                fullscreen={{ ref: fullscreenRef }}
                on={{ click: () => fullscreenRef.current?.enter() }}
                close={() => setViewMedia(false)}
                slides={srcArrImages}
                inline={{
                  style: {
                    width: "100%",
                    maxWidth: "900px",
                    aspectRatio: "3 / 2",
                  },
                }}
              />
            </div>
          )}
          {srcArrImages.length == 1 && (
            <>
              <MessageImage onClick={() => setViewMedia(true)}>
                <img
                  src={srcArrImages[0].src}
                  className="left-image-bubble"
                  style={{
                    objectFit: "contain",
                    maxHeight: "200px",
                    maxWidth: "100%",
                    border: "1px solid #d0cde4",
                    borderRadius: "12px",
                  }}
                />
              </MessageImage>
              <Lightbox
                open={viewMedia}
                plugins={[Zoom]}
                close={() => setViewMedia(false)}
                slides={[{ src: srcArrImages[0].src }]}
                render={{
                  buttonPrev: () => null,
                  buttonNext: () => null,
                }}
                noScroll={{ disabled: true }}
              />
            </>
          )}
          <Divider sx={{ width: "100%" }}></Divider>
          {srcArrOther.map((_item) => (
            <>
              {_item.type === MEDIA_TYPE.AUDIO && (
                <audio src={_item?.src} controls />
              )}
              {_item.type === MEDIA_TYPE.DOCS && (
                <DocMessage url={_item?.src} message={message}></DocMessage>
              )}
              {_item.type === MEDIA_TYPE.VCF && <VCFReader url={_item?.src} />}
              {_item.type === MEDIA_TYPE.UNKNOWN && (
                <RenderUnknownMediaFormat
                  url={_item.src}
                  filename={AppConstants.UNSUPPORTED_MEDIA_MSG}
                  showDownloadBtn={true}
                />
              )}
              <Divider sx={{ width: "100%" }}></Divider>
            </>
          ))}
        </MDBox>
      );
    } else if (mData.length == 1) {
      const firstMedia = mData[0];
      if (firstMedia.type === MEDIA_TYPE.AUDIO) {
        return (
          <>
            <audio src={firstMedia?.src} controls />
          </>
        );
      } else if (firstMedia.type === MEDIA_TYPE.IMAGE) {
        return (
          <>
            <MessageImage onClick={() => setViewMedia(true)}>
              <img
                src={firstMedia.src}
                className="left-image-bubble"
                style={{
                  objectFit: "contain",
                  maxHeight: "200px",
                  maxWidth: "100%",
                  border: "1px solid #d0cde4",
                  borderRadius: "12px",
                }}
              />
            </MessageImage>
            <Lightbox
              open={viewMedia}
              plugins={[Zoom]}
              close={() => setViewMedia(false)}
              slides={[{ src: firstMedia.src }]}
              render={{
                buttonPrev: () => null,
                buttonNext: () => null,
              }}
              noScroll={{ disabled: true }}
            />
          </>
        );
      } else if (firstMedia.type === MEDIA_TYPE.VIDEO) {
        return (
          <>
            <video style={{ height: "150px", width: "150px" }} controls>
              <source src={firstMedia.src} />
            </video>
          </>
        );
      } else if (firstMedia.type === MEDIA_TYPE.DOCS) {
        return (
          <DocMessage url={firstMedia?.src} message={message}></DocMessage>
        );
      } else if (firstMedia.type === MEDIA_TYPE.VCF) {
        return <VCFReader url={firstMedia?.src} />;
      } else if (firstMedia.type === MEDIA_TYPE.TEXT) {
        return <TextReader url={firstMedia?.src} />;
      } else if (firstMedia.type === MEDIA_TYPE.UNKNOWN) {
        return (
          <>
            <RenderUnknownMediaFormat
              url={firstMedia.src}
              filename={AppConstants.UNSUPPORTED_MEDIA_MSG}
              showDownloadBtn={true}
            />
          </>
        );
      } else return <></>;
    }
  };

  useEffect(() => {
    (async () => {
      if (message) {
        if (message?.messageAttchement) {
          const t = await getMediaType(message.messageAttchement);
          const tmp = {
            type: t,
            src: message?.messageAttchement,
          };
          setMedia([tmp]);
        } else if (message?.media && message?.media.length > 0) {
          const tmpArr = [];
          for (const m of message?.media) {
            const t = await getMediaType(m.src);
            const tmp = {
              type: t,
              src: m.src,
            };
            tmpArr.push(tmp);
          }
          setMedia(tmpArr);
        }
      }
    })();
  }, [message?.messageAttchement, message?.media]);

  return (
    <MDBox display="flex" justifyContent="center" alignItems="center">
      {message.status === TELE_MESSAGE_STATUS.sending && (
        <CircularProgress sx={{ position: "absolute" }} />
      )}
      {media && renderMedia(media)}
    </MDBox>
  );
};
export default MediaMessage;
